import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { isAuth } from "../helper/auth";

import { useEffect } from "react";
// import notificationSound from '../../src/assets/audio/notifications.mp3'
// import Home from "../page/Home/Home";
import Header from "../component/Panel/Header";
import Biodata from "../page/Panel/Biodata";
import Dashboard from "../page/Panel/Dashboard";
// import { useSelector } from 'react-redux';

const PanelLayout = () => {
    const user = isAuth()
    const nav = useNavigate();
    useEffect(() => {
    }, [nav])
    // const isLoggedIn = useSelector(state => state.isLoggedIn);
    // console.log(user);
    return (
        <>
            <div>
                {user?.role === "cama" ? null : <Navigate to="/" />}
                <Header />
                <div className=" bg-slate-300 ">
                    <div className="md:p-6 p-0 " >
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/biodata" element={<Biodata />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PanelLayout;



import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';

const Dashboard = () => {

    return (
        <>
            <div className="w-full  h-screen flex justify-center items-center rounded-md  " style={{ filter: "drop-shadow(0px 0px 9px rgba(0,0,0,0.18))" }}>
                <section className=" mx-8 md:mx-20  py-20 bg-white">
                    <div className="flex  flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
                        <div className="relative">
                            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl"> Selamat datang Calon Mahasiswa Baru Tahun Akademik 2024/2025</h2>
                            <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">Silahkan lengapi Biodata anda  </p>
                            <NavLink to={'/biodata'}>
                                <button className=" text-white p-4 border-2 border-[#60282a] bg-gradient-to-r from-[#8a3639] to-[#60282a] text-capitalize rounded-lg">
                                    Isi Biodata
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Dashboard

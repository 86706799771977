import axios from "axios";
import { isAuth } from "./auth";
const user = isAuth()

const token = JSON.parse(localStorage.getItem("token"))
const header = { Authorization: `Bearer ${token}` }
export const notif = (data) => {
    axios.post('api/notification', data, { headers: header })
}
export const notif_read = (id, set) => {
    axios.put('api/notification/' + id, { read: set }, { headers: header })
}
export const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleString("in-ID", options)
}


export const formatDate01 = (dateString) => {
    const date = new Date(dateString);
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


export const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })
}
export const saveHistory = (surat_id, status_id, text) => {
    const catatan = {
        user: user?.username,
        text: text
    }
    const history = {
        surat_id: surat_id,
        status_id: status_id,
        catatan: JSON.stringify(catatan)
    }
    axios.post('api/history', history, {
        headers: { Authorization: `Bearer ${token}` }
    })
}


//Get user info from localstorage
export const allowUser = (users: any) => {
    if (users.user.status === 'APL' || users.user.status === 'BLI' || users.user.status === 'REG') {
        setLocalStorage("token", users.token);
        return true;
    }
    return false;
}
export const allowAdmin = (users: any) => {
    if (users.user.role === 'admin') {
        setLocalStorage("token", users.token);
        return true;
    }
    return false;
}
export const isAuth = () => {
    const token = JSON.parse(localStorage.getItem("token") as string)
    const parseJwt = (token: any) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };
    const decodedJwt = parseJwt(token);

    if (decodedJwt?.exp * 1000 < new Date().getTime()) {
        localStorage.clear();
        return false;
    } else {

        return decodedJwt;
    }

}
export const setLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, JSON.stringify(value));

};
export const removeLocalStorage = () => {
    localStorage.clear();
};

import { useState } from 'react';
import { FaThLarge, FaChevronLeft, FaUserCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { isAuth } from '../../helper/auth';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {

    const user = isAuth()
    const location = useLocation().pathname;
    const [flyer, setFlyer] = useState(false);



    return (
        <>
            <nav className=" md:bg-white bg-[#60282a] shadow-lg md:ml-64 py-3 md:py-4 px-3">
                <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 ">
                    <div className="md:block hidden  ">

                        <button onClick={() => setShowSidebar('-left-64')} className=" text-lg hover:text-gray-200 py-2 px-4 bg-white rounded">
                            <FaThLarge />
                        </button>

                    </div>
                    <div className="md:hidden  ">
                        <button onClick={() => setShowSidebar('left-0')}>
                        </button>
                        <button onClick={() => setShowSidebar('left-0')} className=" text-lg hover:text-gray-200 py-2 px-4 bg-white rounded">
                            <FaThLarge />
                        </button>
                        <div
                            className={`absolute top-4 md:hidden ${showSidebar === 'left-0' ? 'left-52' : '-left-52'
                                } z-50 transition-all duration-300`}>
                            <button className=" text-lg text-white py-2 px-2 bg-gray-400 rounded"
                                onClick={() => setShowSidebar('-left-64')}>
                                <FaChevronLeft />
                            </button>
                        </div>
                    </div>
                    <div className="flex  justify-between items-center w-full">
                        <h4 className="p-3 uppercase text-gray-500 text-md tracking-wider ">
                            <div className='md:block hidden'>
                                {location === '/admin'
                                    ? 'DASHBOARD'
                                    : location.toUpperCase().replace('/', '')}
                            </div>
                        </h4>
                        <div className="flex text-gray-500">
                            <div className="relative">
                                <button type="button" className="grou rounded-md text-gray-500 inline-flex items-center text-base font-sm hover:text-gray-200 focus:outline-none  active:outline-none focus:ring-offset-2 focus:ring-indigo-500 pb-8'" onClick={() => (setFlyer(!flyer))}>
                                    <div className='relative text-2xl '>
                                        <FaUserCircle />
                                    </div>
                                    <span className=' ml-2'> {user?.nama}</span>
                                    <svg className={flyer === true
                                        ? "transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200"
                                        : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                {/* onMouseLeave={() => setFlyer(false)} */}
                                <div onMouseLeave={() => setFlyer(false)} className={flyer ? "  opacity-100 translate-y-0 translate-x-[-62%] transition ease-out duration-200 absolute z-20  -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0  /2 md:-translate-x-[72%]" : " opacity-0 translate-y-1 absolute z-[-1] -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 translate-x-[-62%] md:-translate-x-[72%]"}  >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="px-5 py-3 shadow-md bg-gray-50 space-y-6 justify-start sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">

                                            <div className="flow-root">
                                                <div className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                                    {/* <span className="mr-2">admin</span> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-5 py-5 bg-gray-50 space-y-6 justify-end sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">

                                            {/* <div className="flow-root">
                                                <div className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                                    <span className="mr-2">LogOut</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M4.85 22.05q-1.425 0-2.412-.988-.988-.987-.988-2.412V5.35q0-1.425.988-2.413.987-.987 2.412-.987h7.3v3.4h-7.3v13.3h7.3v3.4ZM16 18.575l-2.4-2.4 2.475-2.475h-7.6v-3.4h7.6L13.6 7.825l2.4-2.4L22.55 12Z" /></svg>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div >
            </nav >



            {/* This example requires Tailwind CSS v2.0+ */}

        </>
    );
}

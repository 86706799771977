import React, { useState, useEffect } from 'react';
import { ValidationJurusan } from '../../../helper/validation';
import axios from 'axios';
import { isAuth } from "../../../helper/auth";

const JurusanDataForm = ({ onSubmitSuccess, status }) => {
    const user = isAuth()
    const token = JSON.parse(localStorage.getItem("token"))
    const header = { Authorization: `Bearer ${token}` }
    const [formJurusan, setFormJurusan] = useState({
        pilihan1: '',
        pilihan2: '',
        pilihan3: '',
        StatusAwalID: '',
        ProgramID: '',
        Jenjang: '',

    });
    const readOnly = status === "N";
    const disable = status === "N";
    const [prodi, setProdi] = useState({})
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        const newData = { ...formJurusan, [name]: value };
        setFormJurusan(newData);
        setErrors(ValidationJurusan(newData));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = ValidationJurusan(formJurusan);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            axios.patch(`api/formulir/dataJurusan/${user.username}`, formJurusan, { headers: header })
                .then((res) => {
                    onSubmitSuccess()
                    console.log("Form data is valid. Submitting form...");
                }).catch((e) => {
                    console.log(e);
                })

        } else {
            console.log("Form data has errors. Not submitting form.");
        }
    }
    useEffect(() => {
        // Logika yang ingin dijalankan setelah komponen dimuat
        getBiodata(); // Contoh memanggil fungsi getBiodata saat komponen dimuat
        // eslint-disable-next-line
    }, []); // Array kosong berarti useEffect ini hanya dijalankan sekali setelah komponen pertama kali dimuat

    const getBiodata = async () => {
        await axios.get(`api/formulir/dataJurusan/${user.username}`, { headers: header })
            .then((v) => {
                setFormJurusan(
                    {
                        pilihan1: v.data[0].Pilihan1 || '',
                        pilihan2: v.data[0].Pilihan2 || '',
                        pilihan3: v.data[0].Pilihan3 || '',
                        StatusAwalID: v.data[0].StatusAwalID || '',
                        ProgramID: v.data[0].ProgramID || '',
                        Jenjang: v.data[0].Jenjang || '',
                    }
                )
                axios.get(`api/formulir/prodi/${v.data[0].Jenjang}`, { headers: header })
                    .then((response) => {
                        setProdi(response.data)
                    })
            })

    }



    return (
        <form onSubmit={handleSubmit} className="md:mt-4 mb-2 w-full p-0 md:p-4 ">
            <div className=' md:grid md:grid-cols-2'>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">
                    <div className="w-full mb-1 ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Jenjang Pendidikan</label>
                        <select value={formJurusan.Jenjang} name="Jenjang" onChange={(e) => handleChange(e)} disabled readOnly className={`disabled:bg-gray-100 disabled:text-gray-400 h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}>
                            <option value="">Pilih Jenjang Pendidikan</option>
                            <option value="E">D3 - Diploma III</option>
                            <option value="C">S1 - Strata 1</option>
                            <option value="B">S2 - Strata 2</option>


                        </select>
                    </div>
                    <div className="w-full  ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Status Awal</label>
                        <select value={formJurusan.StatusAwalID} onChange={(e) => handleChange(e)} name="StatusAwalID" disabled={disable} readOnly={readOnly} className={`disabled:bg-gray-100 disabled:text-gray-400 h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} placeholder="pilih">
                            <option value="">Pilih Status Awal</option>
                            <option value="B">Baru</option>
                            <option value="R">RPL</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.StatusAwalID && errors.StatusAwalID}</small>
                    </div>

                    <div className="w-full mb-1 ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Program Pendidikan</label>
                        <select value={formJurusan.ProgramID} onChange={(e) => handleChange(e)} name="ProgramID" disabled={disable} readOnly={readOnly} className={`disabled:bg-gray-100 disabled:text-gray-400 h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}>
                            <option value="">Pilih Program Pendidikan</option>
                            <option value="R">Reguler</option>
                            <option value="n">Non Reguler</option>
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.ProgramID && errors.ProgramID}</small>
                    </div>
                </div>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">

                    <div className="w-full  ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Pilih Jurusan 1</label>
                        <select value={formJurusan.pilihan1} onChange={(e) => handleChange(e)} name="pilihan1" disabled={disable} readOnly={readOnly} className={`disabled:bg-gray-100 disabled:text-gray-400 h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} placeholder="pilih">

                            <option value="">Jurusan 1</option>
                            {Object.keys(prodi).map((key) => (
                                <option key={key} value={prodi[key].ProdiID}>{prodi[key].Nama}</option>

                            ))}
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.pilihan1 && errors.pilihan1}</small>
                    </div>
                    <div className="w-full  ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Pilih Jurusan 2</label>
                        <select value={formJurusan.pilihan2} onChange={(e) => handleChange(e)} name="pilihan2" disabled={disable} readOnly={readOnly} className={`disabled:bg-gray-100 disabled:text-gray-400 h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} placeholder="pilih">
                            <option value="">Jurusan 2</option>
                            {Object.keys(prodi).map((key) => (
                                <option key={key} value={prodi[key].ProdiID}>{prodi[key].Nama}</option>

                            ))}
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.pilihan2 && errors.pilihan2}</small>
                    </div>
                    <div className="w-full  ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-500">Pilih Jurusan 3</label>
                        <select value={formJurusan.pilihan3} onChange={(e) => handleChange(e)} name="pilihan3" disabled={disable} readOnly={readOnly} className={`disabled:bg-gray-100 disabled:text-gray-400 h-10 bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} placeholder="pilih">
                            <option value="">Jurusan 3</option>
                            {Object.keys(prodi).map((key) => {
                                return (
                                    <option key={key} value={prodi[key].ProdiID}>{prodi[key].Nama}</option>
                                )
                            })}
                        </select>
                        <small className=' text-left flex text-red-500'>{errors.pilihan3 && errors.pilihan3}</small>
                    </div>

                </div>


            </div>
            {
                status === 'Y' ? (<button className='bg-[#60282a] hover:bg-red-900 text-white font-bold py-2 px-4 rounded ' type='submit'>Selanjutnya</button>) : ""
            }
        </form>
    );
};

export default JurusanDataForm;
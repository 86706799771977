import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeLayout from '../../layout/HomeLayout';
import { FaFileDownload } from 'react-icons/fa';
const Alur = () => {



    return (
        <>
            <HomeLayout>

                <div className="w-full flex justify-center md:mt-24 mt-20  h-auto  ">

                    <div className='w-full md:mx-8 h-screen-[28px] mb-8 md:mt-8 bg-white'>
                        <p className="w-full   text-2xl p-4 font-bold  text-center text-black mt-4">
                            ALUR PENDAFTARAN
                        </p>
                        <div className="relative w-full flex flex-col justify-center overflow-hidden">
                            <div className="w-full  mx-auto px-4 md:px-6 ">
                                <div className="flex flex-col justify-center divide-y divide-slate-200 py-6">
                                    <div className="w-full  mx-auto">
                                        <div className="md:min-h-screen  flex flex-col justify-center">
                                            <div className="py-3 sm:max-w-xl sm:mx-auto w-full px-2 sm:px-0">
                                                <div className="relative text-gray-700 antialiased text-sm font-semibold">
                                                    <div className="hidden sm:block w-1 bg-[#60282a7d] absolute h-full left-1/2 transform -translate-x-1/2"></div>
                                                    <div className="mt-6 sm:mt-0 sm:mb-4">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-24 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">Mulai</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 sm:mt-12 sm:mb-12">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-start w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pr-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Formulir Pendaftaran</div>
                                                                        <div>Silahkan isi formulir pendaftaran calon mahasiswa Universitas Ekasakti</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">1</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6 sm:mt-0 sm:mb-12">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-end w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pl-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Login Ke Portal PMB</div>
                                                                        <div>Setelah mengisi formulir pendaftaran silahkan login ke portal PMB. <a className=' text-blue-300' href="https://pmb.unespadang.ac.id/">https://pmb.unespadang.ac.id/</a> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">2</span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6 sm:mt-0 sm:mb-12">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-start w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pr-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Lengkapi Biodata Diri</div>
                                                                        <div>Setelah berhasil login maka silahkan melengkapi pengisian biodata diri</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">3</span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6 sm:mt-0">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-end w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pl-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Upload Dokumen</div>
                                                                        <div>Bagi calon mahasiswa calon Mahasiswa Baru diwajibkan untuk upload dokumen KTP, Kartu Keluarga, Ijazah Terakhir,Pas Foto dan Setifikat Lainya </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">4</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 sm:mt-0 sm:mb-12">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-start w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pr-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Lakukan Pembayaran</div>
                                                                        <div>Silahkan lakukan pembayaran Formulir dan SPP di PMB Rektor Lt. 1 Universitas Ekasati Padang</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">5</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 sm:mt-0">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-end w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pl-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Proses NIM/No. BP</div>
                                                                        <div>Setelah anda melakukan proses pembayaran uang kuliah, maka silahkan anda proses Nomor Induk Mahasiswa (NIM) anda di PMB Rektorat Lt. 1 Universitas Ekasakti Padang</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">6</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 sm:mt-0 sm:mb-12">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="flex justify-start w-full mx-auto items-center">
                                                                <div className="w-full sm:w-1/2 sm:pr-8">
                                                                    <div className="p-4 text-white bg-[#9a5658] rounded shadow">
                                                                        <div className=' font-extrabold text-lg'>Pengisian KRS </div>
                                                                        <div>Melakukan pengisian KRS (Kartu Rencana Studi) Melalui Portal <a className=' text-blue-300' href="https://siakad.unespadang.ac.id/">https://siakad.unespadang.ac.id/</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-10 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">5</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 sm:mt-0 sm:mb-4">
                                                        <div className="flex flex-col sm:flex-row items-center">
                                                            <div className="rounded-full bg-[#60282a] border-white border-4 w-24 h-10 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                                <span className="h-5 w-5 text-xl flex items-center justify-center text-white">Selesai</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className=' flex justify-center items-center w-full mb-14' href={require('../../assets/pdf/Panduan Pendaftaran.pdf')} download>
                                    <div className=" ml-1 flex justify-center items-center w-96 relative overflow-hidden gap-2 px-5 py-[13px] rounded-[30px] bg-[#049b4b]" style={{ boxShadow: "0px 1px 2px 0 rgba(15,23,42,0.06)" }}>
                                        <div className=' text-white text-2xl'>
                                            <FaFileDownload />
                                        </div>
                                        <p className="  text-base font-medium text-left text-white">
                                            UNDUH PANDUAN PENDAFTARAN
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeLayout>

        </>
    )
}

export default Alur
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { isAuth } from "../helper/auth";

import { useEffect } from "react";
import Dashboard from "../page/Admin/Dashboard";
import Daftar from "../page/Admin/Daftar";
import Sidebar from "../component/Admin/Sidebar";
import Biodata from "../page/Admin/Biodata";

const AdminLayout = () => {
    const user = isAuth()
    const nav = useNavigate();
    useEffect(() => {
    }, [nav])
    // const isLoggedIn = useSelector(state => state.isLoggedIn);

    return (
        <>
            <div>
                {(user?.role === "admin") ? null : <Navigate to="/administrator" />}
                <Sidebar />

                <div className=" bg-gray-300 overflow-x-auto md:ml-64">
                    <div className="  md:m-5 m-0 " >
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/daftar" element={<Daftar />} />
                            <Route path="/daftar/:AplikanID" element={<Biodata />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminLayout;


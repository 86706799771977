
import mhs from "../assets/img/mhs.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Header from "../component/Home/Header";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FaCheckCircle } from "react-icons/fa";
import { Backdrop, CircularProgress } from "@mui/material";

function Daftar() {
    // const { snapEmbeded } = MidtransSnap()
    const [formInput, setFormInput] = useState({
        nama: '',
        email: '',
        jenjang: '',
    })
    const [response, setResponse] = useState()
    const formInputHeandler = async (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }


    const [finish, setFinish] = useState(false);
    const [load, setLoad] = useState(false);
    const registrasi = (e) => {
        setLoad(true)
        e.preventDefault();
        // console.log(formInput);
        // return false
        axios.post('api/users/aplikan', formInput, {
            withCredentials: false,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }).then((res) => {
            console.log(res);
            if (res.data?.error_code > 300) {
                swal("Gagal Daftar", `${res.data?.error_messages} !`, "error");
                setLoad(false)
                return false
            }
            setResponse(res.data.data)
            setFinish(true)
            setLoad(false)
        }).catch(() => {
        })
    }

    const handleClose = () => {
        // setFinish(false);
    };
    return (
        <>
            <Header />
            {finish && (
                <>
                    <Dialog
                        open={true}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="  w-full p-4 flex justify-center items-center">
                            <div className="text-center rounded-lg w-full  bg-white">
                                <div className="text-center w-full h-full ">
                                    <div className="text-center" >
                                        <div>
                                            <div>
                                                <div className=" py-4" >
                                                    <div className=" leading-5" >
                                                        <div className="p-4 flex text-7xl justify-center text-green-500">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <div className=" text-lg  font-bold" >Berhasil Daftar</div>
                                                        {/* <div className=" text-sm font-light " >Jenjang Pendidikan : {response?.jenjang}</div> */}
                                                    </div>
                                                    <div className="mt-2 text-md font-light" >
                                                        Silahkan <NavLink to={'/login'} ><span className=" font-extrabold">Login</span></NavLink>  Menggunakan Pin dan Password dibawah
                                                    </div>
                                                    <div className=" font-bold text-2xl md:py-2 py-2">
                                                        Pin : {response?.username}
                                                    </div>
                                                    <div className=" font-bold text-2xl md:py-2 py-2">
                                                        Password : {response?.password}
                                                    </div>
                                                    <div className="  text-sm font-light" >
                                                        <div>
                                                            PIN DIKIRIM KE:
                                                        </div>
                                                        <span className=" font-medium">{response?.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" w-full flex pb-4 justify-center">
                                            <NavLink to={`/login`} >
                                                <Button variant="outlined" color="error" autoFocus>
                                                    <div className="text-red-500">
                                                        login
                                                    </div>
                                                </Button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </div>

                    </Dialog>
                </>
            )}
            <div>
                <div className=" md:grid md:grid-cols-3 h-screen md:bg-white bg-gray-300">
                    <div className=" md:block hidden  mt-20 w-full min-h-[720] col-span-2">
                        <div className="w-full min-h-[720]">
                            <img src={mhs} alt="lol" className="w-[284px] h-[283px] absolute left-[577.5px] top-[257.5px] object-contain" />
                            <div className="w-[489px] h-[269.44px]">
                                <p className="w-[471.84px] h-[40.96px] absolute left-[100px] top-[292.1px] text-[40px] font-semibold text-left text-black">
                                    <span className="w-full leading-3 text-[40px] font-semibold text-left text-black">
                                        Daftar di Universitas Ekasakti
                                    </span>
                                </p>
                                <p className="w-[489px] h-[151.54px] absolute left-[100px] top-[410px] text-[22px] font-medium text-left text-black">
                                    Bergabung jadi mahasiswa dan mulai pengalaman belajar disini.
                                </p>
                            </div>
                            <div className="w-[324.5px] h-[46.11px]">
                                <p className="w-[324.09px] h-[23.62px] absolute left-[100px] top-[482px] text-xl text-left text-black">
                                    Jika kamu sudah memiliki Akun
                                </p>
                                <p className="w-[324.09px] h-[23.62px] absolute left-[100px] top-[504.49px] text-xl text-left">
                                    <span className="w-[324.09px] h-[23.62px] text-xl text-left text-black">Anda dapat </span>
                                    <span className="w-[324.09px] h-[23.62px] text-xl font-semibold text-left text-[#4d47c3]">
                                        <NavLink to={'/login'}> Login disini !</NavLink>
                                    </span>
                                </p>
                            </div>
                        </div >
                    </div >

                    <div className="right flex justify-center items-center  w-full h-full  ">
                        <div className="flex w-full min-h-screen items-center justify-center">
                            <div className="w-full m-4 bg-white relative py-8 rounded-xl bg-transparent bg-clip-border text-gray-700 md:shadow-none shadow-lg">
                                <div className="px-8 w-full h-auto font-sans text-2xl font-bold   text-blue-gray-900 ">
                                    PENDAFTARAN CALON MAHASISWA BARU
                                </div>
                                <div className=" flex w-full h-full justify-center items-center">
                                    <form onSubmit={registrasi} className="  md:mt-4 mb-2 w-full p-8 md:p-4 sm:w-96">
                                        <div className="mb-4 ">
                                            <div className="w-full mb-2 ">
                                                <label className="block mb-1 text-left text-sm font-light text-gray-700">Nama</label>
                                                <input value={formInput.nama} name="nama" onChange={formInputHeandler} type="text" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Nama Lengkap" required />
                                            </div>
                                            <div className="w-full mb-2 ">
                                                <label className="block mb-1 text-left text-sm font-light text-gray-700">Email</label>
                                                <input value={formInput.email} name="email" onChange={formInputHeandler} type="email" id="email" className="bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Email" required />
                                            </div>
                                            <div className="w-full mb-2 ">
                                                <label className="block mb-1 text-left text-sm font-light text-gray-500">Jenjang Pendidikan</label>
                                                <select name="jenjang" value={formInput.jenjang} onChange={formInputHeandler} className=" bg-gray-50 border border-[#bca0a1]   text-gray-900 text-sm rounded-lg  focus:ring-red-900 h-10 focus:border-red-900 block w-full p-2.5  " placeholder="pilih">
                                                    <option value='' >Jenjang Pendidikan</option>
                                                    <option value="E">D3</option>
                                                    <option value="C">S1</option>
                                                    <option value="B">S2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button className="mt-6 block w-full select-none rounded-lg bg-[#60282a] py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="submit" data-ripple-light="true">
                                            Daftar
                                        </button>
                                        <p className="mt-4 block text-center font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
                                            <span> Sudah Punya Akun?</span>
                                            <a className="font-semibold text-pink-500 transition-colors hover:text-blue-700" href="/login" >Login</a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Backdrop
                sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Daftar;
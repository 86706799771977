import { Icon } from '@mui/material';
import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from "../../assets/img/unes-small.png";
import Menu from "../../json/menu_admin.json";
import AdminNavbar from './Navbar';
import { removeLocalStorage } from '../../helper/auth';

const Sidebar = () => {
    // const nav = useNavigate();
    const nav = useNavigate();
    const paths = useLocation().pathname;
    const [showSidebar, setShowSidebar] = useState('-left-64');
    const path = paths

    let url = path

    async function logOut() {
        removeLocalStorage()
        nav('/administrator')
    }

    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div onMouseLeave={() => setShowSidebar('-left-64')} className=' z-10'>
                <div className={`h-screen bg-[#60282a]  fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl  w-64 z-10 py-4 px-3 transition-all duration-300`}>
                    <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                        <div className=" text-center w-full inline-block">
                            <div className=' flex w-full justify-center'>
                                <img className='p-2' src={logo} width={120} alt="logo" />

                            </div>
                            <div className=' text-white font-bold' >UNIVERSITAS EKASAKTI</div>
                            {/* <div className='text-white font-bold' >Level Staff</div> */}
                        </div>
                        <div className="flex flex-col">
                            <hr className="my-4 min-w-full" />
                            <ul className="flex-col min-w-full flex list-none">
                                {Menu.map((item, index) => {
                                    return (
                                        <NavLink key={index} to={item.link} className={url === `${item.link}` ? " rounded-lg  shadow-md  text-white bg-[#895557]  " : "rounded-lg   hover:bg-[#6a2d2f] hover:text-white text-white bg-[#60282a] "}>
                                            <li onClick={() => setTimeout(() => setShowSidebar('-left-64'), 500)} className="flex font-semibold items-center gap-4 text-sm  px-3 py-2 rounded-lg" >
                                                <Icon >{item.icon}</Icon>
                                                {item.name}
                                            </li>
                                        </NavLink>
                                    )
                                })}
                                <hr className='my-4' />
                                <div className="rounded-lg hover:bg-[#6a2d2f] hover:text-white text-white bg-[#60282a] ">
                                    <li onClick={logOut} className="flex items-center gap-4 text-sm font-light px-4 py-3 rounded-lg" >
                                        <Icon>logout</Icon>
                                        Log Out
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar
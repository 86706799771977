import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeLayout from '../../layout/HomeLayout';
const Jadwal = () => {



    return (
        <>
            <HomeLayout>

                <div className="w-full flex justify-center mt-24  h-auto  ">

                    <div className='w-full mx-8 h-screen-[28px] mb-8 mt-8 bg-white'>
                        <p className="w-full   text-2xl p-4 font-bold  text-center text-black mt-4">
                            JADWAL PENDAFTARAN
                        </p>
                        <div className="relative w-full flex flex-col justify-center overflow-hidden">
                            <div className="w-full max-w-6xl mx-auto px-4 md:px-6 ">
                                <div className="flex flex-col justify-center divide-y divide-slate-200 py-6">



                                    <div className="w-full max-w-3xl mx-auto">

                                        <div className="-my-6">

                                            <div className="relative pl-8 sm:pl-40 py-6 group">
                                                <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[8.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#754546] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[8.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                                    <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-28 h-6 mb-3 sm:mb-0 text-white bg-[#754546] rounded-full">02 Mei 2024</time>
                                                    <div className="text-xl font-bold text-slate-900">Gelombang I</div>
                                                </div>
                                                <div className="text-slate-500">Pendaftaran Mehasiswa Baru  Gelombang I dari 02 Mei 2024 s.d 31 Mei 2024 </div>
                                            </div>
                                            <div className="relative pl-8 sm:pl-40 py-6 group">
                                                <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[8.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#754546] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[8.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                                    <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-28 h-6 mb-3 sm:mb-0 text-white bg-[#754546] rounded-full">01 Agust 2024</time>
                                                    <div className="text-xl font-bold text-slate-900">Gelombang II</div>
                                                </div>
                                                <div className="text-slate-500">Pendaftaran Mahasiswa Baru  Gelombang II dari 01 Agustus 2024 s.d 14 September 2024</div>
                                            </div>



                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeLayout>

        </>
    )
}

export default Jadwal
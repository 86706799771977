import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Header from "../component/Home/Header";

import MidtransSnap from "../hook/MidtransSnap";

function Payment() {
    let { token } = useParams();
    const [snapShow, setSnapShow] = useState(false);
    const { snapEmbeded } = MidtransSnap();

    const snapContainerRef = useRef(null);
    const buttonRef = useRef(null);

    const initializeSnap = async () => {
        snapEmbeded(token, snapContainerRef.current.id);
        setSnapShow(true);
    };


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (snapContainerRef) {

                buttonRef.current.click();
            }
            else {
                console.log("rggf");
            }
        }, 2000);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line
    }, []); // Efek ini hanya dijalankan saat komponen pertama kali dimount

    return (
        <>
            <Header />
            <div className="flex justify-center h-svh min-h-screen md:mt-24 mt-20  w-full">
                <div style={{ display: snapShow ? "flex" : "none" }} className="flex justify-center h-full w-full">
                    <div
                        ref={snapContainerRef}
                        className="flex justify-center md:h-[645px] h-[92%] md:w-2/3 w-full md:p-8 px-4 pt-2"
                        id="snap-container"

                    ></div>
                </div>
            </div>
            <button className=" hidden" ref={buttonRef} onClick={(e) => initializeSnap()}>
                Klik Saya
            </button>
        </>
    );
}

export default Payment;

import { useState, useEffect } from 'react';
import PersonalDataForm from '../../component/Admin/Forms/PersonalDataForm'; // New component for personal data form
import AddressDataForm from '../../component/Admin/Forms/AddressDataForm'; // New component for address data form
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DocumentDataForm from '../../component/Admin/Forms/DocumentDataForm';
import JurusanDataForm from '../../component/Admin/Forms/JurusanDataForm';
import SekolahDataForm from '../../component/Admin/Forms/SekolahDataForm';
import SelesaiPage from '../../component/Admin/Forms/SelesaiPage';
import { NavLink, useParams } from "react-router-dom";
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
const Biodata = () => {
    let { AplikanID } = useParams();


    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState('')

    const token = JSON.parse(localStorage.getItem("token"));
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }

    useEffect(() => {
        getStatus();
        // eslint-disable-next-line
    }, []);

    const getStatus = async () => {
        await axios.get(`/api/formulir/status/${AplikanID}`, header)
            .then(response => {
                setStatus(response.data)
            })
            .catch(error => {
                console.error(`Failed to get $`, error);
            })

    }
    const tabsData = [
        {
            label: 'Data Jurusan',
            content: <JurusanDataForm AplikanID={AplikanID} status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },
        {
            label: 'Data Pribadi',
            content: <PersonalDataForm AplikanID={AplikanID} status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },
        {
            label: 'Data Alamat',
            content: <AddressDataForm AplikanID={AplikanID} status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },
        {
            label: 'Data Sekolah',
            content: <SekolahDataForm AplikanID={AplikanID} status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />,
        },

        {
            label: 'Upload Dokumen',
            content: <DocumentDataForm AplikanID={AplikanID} status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />
        },
        {
            label: 'Selesai',
            content: <SelesaiPage AplikanID={AplikanID} status={status[0]?.NA} onSubmitSuccess={() => goToNextTab()} />
        }
    ];
    const goToNextTab = async () => {
        if (activeTab < tabsData.length - 1) {
            setActiveTab(activeTab + 1);
        }
    };
    useEffect(() => {
        getStatus();
        // eslint-disable-next-line
    }, [activeTab]);
    return (
        <>
            <div className="w-full rounded-md h-screen" style={{ filter: "drop-shadow(0px 0px 9px rgba(0,0,0,0.18))" }}>
                <div className="p-4 bg-white">
                    <div className='px-2 flex items-center'>
                        <div className=' w-1/4 cursor-pointer text-2xl text-[#60282a]'>
                            <NavLink to={'../Daftar'}> <FaArrowLeft /></NavLink>
                        </div>
                        <h1 className=" w-1/2 text-2xl px-4 font-bold ">Formulir PMB</h1>
                    </div>
                    <div className="p-4">
                        <div className="flex tabsData justify-center md:px-6 px-2 flex-wrap mb-4">
                            {tabsData.map((tab, index) => {

                                return (
                                    <button key={index} className={`mb-2 md:mb-0 border-x-[0.5px] border-gray-300 py-2 px-4  ${activeTab === index ? 'bg-[#60282a] font-bold text-white' : ' font-light hover:bg-gray-300 bg-gray-200'}`} onClick={() => setActiveTab(index)}>
                                        {tab.label}
                                    </button>
                                )

                            })}
                        </div>
                        <div className='w-full'>{tabsData[activeTab].content}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Biodata;

import { useRef } from "react"
const Pagination = ({ currentPage, totalPages, selectPage, limitPage, nextPage, prevPage }) => {
    const pageRef = useRef(null)
    return (
        <div className=" px-4 pb-4 md:min-w-max   bg-white rounded flex md:justify-end justify-center">

            <div className="md:flex block  h-full">
                <div className="  p-1">
                    <nav aria-label="Page h-full  navigation example">
                        <div className="  h-full">
                            <select onChange={limitPage} className="py-2 px-3 w-full leading-tight text-gray-500 rounded border border-gray-300 h-full">
                                <option value="10">Show 10</option>
                                <option value="25">Show 25</option>
                                <option value="50">Show 50</option>
                                <option value="100">Show 100</option>
                            </select>
                        </div>
                    </nav>
                </div>
                <div className="p-1 ">

                    <nav aria-label="Page   navigation example">
                        <ul className="inline-flex -space-x-px">
                            <li>
                                <div className={" cursor-pointer py-2 px-3 ml-0 leading-tight text-gray-500  rounded-l border border-gray-300  " + (currentPage > 1 ? 'bg-white hover:bg-gray-100' : 'bg-gray-200  ')} onClick={prevPage}>Previous</div>
                            </li>
                            {(() => {
                                if (currentPage > 6) {
                                    return (
                                        <li >
                                            <div onClick={selectPage.bind(this, 1)} className={"cursor-pointer py-2 px-3 leading-tight text-gray-500 border bg-white border-gray-300 "}>1..</div>
                                        </li>
                                    )
                                }
                            })()}
                            {(() => {
                                let li = [];
                                let limitPage = 3
                                let start = currentPage > limitPage ? (currentPage - limitPage) : 1
                                let end = currentPage < (totalPages - limitPage) ? currentPage + limitPage : totalPages

                                for (start; start <= end; start++) {
                                    li.push(
                                        <li key={start}>
                                            <div ref={pageRef} onClick={selectPage.bind(this, start)} className={"cursor-pointer py-2 px-3 leading-tight text-gray-500 border border-gray-300 " + (currentPage === start ? 'bg-gray-600 text-white ' : 'bg-white')}>{start}</div>
                                        </li>
                                    );
                                }

                                return li;

                            })()}
                            {(() => {
                                if (currentPage < (totalPages - 3)) {
                                    return (
                                        <li >
                                            <div onClick={selectPage.bind(this, totalPages)} className={"cursor-pointer py-2 px-3 leading-tight text-gray-500 border border-gray-300 " + (currentPage === totalPages ? 'bg-gray-600 text-white ' : 'bg-white')}>...{totalPages}</div>
                                        </li>
                                    )
                                }
                            })()}
                            <li>
                                <div className={" cursor-pointer py-2 px-3 leading-tight text-gray-500 bg-white rounded-r border border-gray-300 " + (currentPage >= totalPages ? 'bg-gray-200  ' : 'bg-white hover:bg-gray-100')} onClick={nextPage}>Next</div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Pagination;
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./page/Login";
import Home from "./page/Home/Home";
import PanelLayout from "./layout/PanelLayout";
import Daftar from "./page/Daftar";
import Payment from "./page/Payment";
import AdminLayout from "./layout/AdminLayout";
import LoginAdmin from "./page/LoginAdmin";
import Jadwal from "./page/Home/Jadwal";
import Alur from "./page/Home/Alur";
function App() {
  return (
    <div className="App">

      <Routes>
        <Route path="/administrator" element={<LoginAdmin />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        {/* <Route path="/admin/users/:AplikanID" element={<Biodata />} /> */}
        <Route path="/*" element={<PanelLayout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/daftar" element={<Daftar />} />
        <Route path="/jadwal" element={<Jadwal />} />
        <Route path="/alur" element={<Alur />} />
        <Route path="/payment/:token" element={<Payment />} />
        <Route path="/daftar/success" element={<Daftar />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
// store.js

import { legacy_createStore } from 'redux';

// Action types
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';

// Initial state
const initialState = {
    isLoggedIn: false,
    token: null
};

// Reducer
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                token: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                token: null
            };
        default:
            return state;
    }
};

// Action creators
export const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: token
});

export const logout = () => ({
    type: LOGOUT
});

// Create store
const store = legacy_createStore(authReducer);

export default store;

export default function Validation(values) {

    const errors = {}
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/

    if (values.name === "") {
        errors.name = "Name is Required";
    }

    if (values.email === "") {
        errors.email = "Email is Required";
    } else if (!email_pattern.test(values.email)) {
        errors.email = "Email is not valid";
    }

    if (values.password === "") {
        errors.password = "Password is Required";
    } else if (!password_pattern.test(values.password)) {
        errors.password = "Password bukan pasword";
    }
    return errors
}
export function ValidationSurat(values) {

    const errors = {}
    if (values.nomor_surat === "") {
        errors.nomor_surat = "Nomor Surat wajib diisi";
    }
    if (values.judul_surat === "") {
        errors.judul_surat = "Judul Surat wajib diisi";
    }
    if (values.perihal_surat === "") {
        errors.perihal_surat = "Perihal wajib diisi";
    }
    return errors
}

export function ValidationJurusan(values) {

    const errors = {}
    if (values.pilihan1 === "") {
        errors.pilihan1 = "NIK wajib diisi";
    }
    if (values.pilihan2 === "") {
        errors.pilihan2 = "Nama wajib diisi";
    }
    if (values.pilihan3 === "") {
        errors.pilihan3 = "Jenis kelamin wajib diisi";
    }
    if (values.StatusAwalID === "") {
        errors.StatusAwalID = "Tempat lahir wajib diisi";
    }
    if (values.ProgramID === "") {
        errors.ProgramID = "Tanggal lahir wajib diisi";
    }
    return errors
}
export function ValidationBiodata(values) {

    const errors = {}
    if (values.nik === "") {
        errors.nik = "NIK wajib diisi";
    }
    if (values.nama === "") {
        errors.nama = "Nama wajib diisi";
    }
    if (values.kelamin === "") {
        errors.kelamin = "Jenis kelamin wajib diisi";
    }
    if (values.tempatLahir === "") {
        errors.tempatLahir = "Tempat lahir wajib diisi";
    }
    if (values.tanggalLahir === "") {
        errors.tanggalLahir = "Tanggal lahir wajib diisi";
    }
    if (values.agama === "") {
        errors.agama = "Agama wajib diisi";
    }
    if (values.wargaNegara === "") {
        errors.wargaNegara = "Warga Negara wajib diisi";
    }
    if (values.kebangsaan === "") {
        errors.kebangsaan = "Kebangsaan wajib diisi";
    }
    if (values.golonganDarah === "") {
        errors.golonganDarah = "Golongan darah wajib diisi";
    }
    if (values.statusPerkawinan === "") {
        errors.statusPerkawinan = "Status perkawinan wajib diisi";
    }
    if (values.tinggiBadan === "") {
        errors.tinggiBadan = "Tinggi badan wajib diisi";
    }
    if (values.beratBadan === "") {
        errors.beratBadan = "Berat badan wajib diisi";
    }
    if (values.alatTransportasi === "") {
        errors.alatTransportasi = "Alat transportasi wajib diisi";
    }
    if (values.ukuranJaket === "") {
        errors.ukuranJaket = "Ukuran jaket wajib diisi";
    }
    return errors
}
export function ValidationDataAlamat(values) {
    const errors = {}
    if (values.tempatTinggal === "") {
        errors.tempatTinggal = "Tempat tinggal wajib diisi";
    }
    if (values.alamatLengkap === "") {
        errors.alamatLengkap = "Alamat lengkap wajib diisi";
    }

    if (values.kodePos === "") {
        errors.kodePos = "Kode pos wajib diisi";
    }
    if (values.noTelp === "") {
        errors.noTelp = "Nomor telepon wajib diisi";
    }
    if (values.dusun === "") {
        errors.dusun = "Dusun wajib diisi";
    }
    if (values.provinsi === "") {
        errors.provinsi = "Provinsi wajib diisi";
    }
    if (values.kabupatenKota === "") {
        errors.kabupatenKota = "Kabupaten/Kota wajib diisi";
    }
    if (values.kelurahan === "") {
        errors.kelurahan = "Kelurahan wajib diisi";
    }
    if (values.kecamatan === "") {
        errors.kecamatan = "Kecamatan wajib diisi";
    }
    return errors
}

export function ValidationDataSekolah(values) {
    const errors = {};

    if (values.AsalSekolah === "") {
        errors.AsalSekolah = "Asal Sekolah wajib diisi";
    }
    if (values.Jurusan === "") {
        errors.Jurusan = "Jurusan wajib diisi";
    }
    if (values.NilaiUjian === "") {
        errors.NilaiUjian = "Nilai Ujian wajib diisi";
    }
    if (values.NilaiRapor === "") {
        errors.NilaiRapor = "Nilai Rapor wajib diisi";
    }
    if (values.AlamatSekolah === "") {
        errors.AlamatSekolah = "Alamat Sekolah wajib diisi";
    }
    if (values.TahunLulus === "") {
        errors.TahunLulus = "Tahun Lulus wajib diisi";
    }
    if (values.PendidikanTerakhir === "") {
        errors.PendidikanTerakhir = "Pendidikan Terakhir wajib diisi";
    }

    return errors;
}

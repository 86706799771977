import unes_s from "./../../assets/img/unes-small.png";
import fb from "./../../assets/img/fb.png";
import ig from "./../../assets/img/ig.png";
import wa from "./../../assets/img/wa.png";
import { FaGlobe, FaPhoneAlt } from "react-icons/fa";
function Footer() {
    return (
        <>
            {/* footer */}
            <div className="w-full  h-full relative" style={{ filter: "drop-shadow(1px -4px 10px rgba(0,0,0,0.25))" }}>
                <div className=" bg-[#60282a] md:grid md:grid-cols-3  grid-cols-none min-h-[150px] w-full h-full   p-8 " style={{ filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25))" }}>
                    <div className=" flex px-2 items-center">
                        <img src={unes_s} className="w-[72px] pr-3 h-auto object-contain " alt="lol" />
                        <div>
                            <p className=" text-base font-bold text-left text-[#e2e2e2]">
                                UNIVERSITAS EKASAKTI - AKADEMI AKUNTANSI INDONESIA
                            </p>
                            <p className="w-full font-light text-sm text-left text-[#e2e2e2]">
                                Jl. Veteran No. 26B Purus, Kec. Padang Barat
                            </p>
                        </div>
                    </div>
                    <div className=" flex justify-center px-2 w-full h-full">
                        <div className=' w-full h-full'>
                            <div className="  text-xl font-bold text-left text-[#e2e2e2]">
                                Hubungi
                            </div>
                            <div className=" text-base text-left text-[#e2e2e2]">
                                <a className=" flex items-center" href="https://unespadang.ac.id">
                                    <FaGlobe />
                                    <span className="px-2 text-base text-left text-[#e2e2e2]">
                                        unespadang.ac.id
                                    </span>
                                </a>
                                <a className=" flex items-center" href="https://siakad.unespadang.ac.id">
                                    <FaGlobe />
                                    <span className="px-2 py-1 text-base text-left text-[#e2e2e2]">
                                        siakad.unespadang.ac.id
                                    </span>
                                </a>
                                <div className=" flex items-center">
                                    <FaPhoneAlt />
                                    <span className="px-2 py-1 w-full h-full text-base text-left text-[#e2e2e2]">Official PMB UNES (085668419278)</span>
                                </div>
                                <div className=" flex items-center">
                                    <FaPhoneAlt />
                                    <span className="px-2 py-1 w-full h-full text-base text-left text-[#e2e2e2]">Wawan Sumarno, SP., M.Si (083845984595)</span>
                                </div>
                                <div className=" flex items-center">
                                    <FaPhoneAlt />
                                    <span className="px-2 py-1 w-full h-full text-base text-left text-[#e2e2e2]">Dr. Iyah Faniyah, S.H., M.Hum (PASCASARJANA) (081261278868)</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=" flex md:justify-end justify-center items-center ">
                        <a href="https://www.facebook.com/profile.php?id=100088674894953">

                            <img
                                src={fb}
                                className="w-[44.27px] object-contain p-1" alt="img"
                            />
                        </a>
                        <a href="https://www.instagram.com/universitasekasakti.aai_padang">
                            <img
                                src={ig}
                                className="w-[44.27px]   object-contain p-1" alt="img"
                            />
                        </a>

                        <a href="https://wa.me/6285668419278">
                            <img
                                src={wa}
                                className="w-[44.27px] object-contain p-1" alt="img"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="fixed right-8 lg:inset-x-auto bottom-6  lg:right-8 xl:right-10 xl:bottom-8">
                <div className=" w-full">
                    <div className=" shadow-lg bg-white rounded-lg border border-[#dfdfdf] ">
                        <div className="flex flex-wrap items-center justify-between">
                            <a href="https://wa.me/6285668419278" className=" flex items-center flex-1 w-full">
                                <span className="flex rounded-lg">
                                    <img
                                        src={wa}
                                        className=" w-14 object-contain " alt="img"
                                    />
                                </span>
                                <p className="mx-3  font-medium tracking-wide text-gray-700 truncate">
                                    Hubungi Kami
                                </p>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;
import logo from "../assets/img/logo.png";
import { useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import md5 from "md5";
import axios from "axios";
import { allowUser, isAuth } from "../helper/auth";
import swal from "sweetalert";
// import { loginSuccess } from '../store.jsx';
function Login() {
    const user = isAuth()
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const nav = useNavigate();
    const loginHandler = (e) => {
        setLoading(true);
        e.preventDefault();
        axios.post('api/auth', {
            'username': username,
            'password': md5(password)
        }, {
            withCredentials: false, headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }).then((response) => {

            // dispatch(loginSuccess(response.data.token));
            if (allowUser(response.data)) {
                nav("/dashboard");
            } else {
                swal("Gagal", "Dilarang Akses", "error");
                setLoading(false);
            }
        }).catch((error) => {
            swal("Gagal", "Username atau Password salah!", "error");
            setLoading(false);
        })
    }

    return (

        <div className="flex bg-gray-100 h-screen justify-center items-center">
            {user?.role === "cama" ? <Navigate to="/dashboard" /> : null}
            <div className=" w-4/5 md:w-2/3">
                {loading ? null : null}
                <div className=" w-full flex justify-center shadow-lg ">
                    <div className=" w-full h-[400px] md:flex">
                        <div
                            className="relative rounded-l-md overflow-hidden md:flex w-1/2 bg-batik justify-around items-center hidden">
                            <NavLink to={'/'}>
                                <div className="static">
                                    <div className=" pb-5 flex justify-center w-full">
                                        <img className=" drop-shadow-lg w-2/6" src={logo} alt="" />
                                    </div>


                                    <div>
                                        <h1 className="text-white font-bold text-2xl font-sans">UNIVERSITAS EKASAKTI</h1>
                                    </div>
                                    <div>
                                        <p className="text-white text-xl font-extralight ">PMB </p>
                                    </div>

                                    <div className="absolute leading-4 bottom-5 font-extralight left-0 right-0 text-gray-400 ">
                                        <div className=" ">
                                            <small>
                                                Powered By Univertitas Ekasakti
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className=" rounded-r-md flex md:w-1/2 justify-center py-10 items-center bg-white">
                            <form onSubmit={loginHandler} className=" md:w-2/3 bg-white">

                                <h1 className="text-gray-800 font-bold text-3xl mb-1">LOGIN</h1>
                                <h1 className="text-gray-800 font-bold text-lg mb-1"> CALON MAHASISWA BARU</h1>
                                <p className="text-sm font-normal text-gray-600 mb-7">Masuk menggunakan PIN dan Password anda</p>
                                <div className="flex items-center border-2 py-2 px-3 rounded-lg mb-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#a73233]" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                            clipRule="evenodd" />
                                    </svg>
                                    <input value={username} onChange={(e) => setUsername(e.target.value)} className="pl-2 w-full outline-none border-none" type="text" required placeholder="PIN" />
                                </div>


                                <div className="flex items-center border-2 py-2 px-3 rounded-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#a73233]" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fillRule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clipRule="evenodd" />
                                    </svg>
                                    <input className="pl-2 w-full outline-none border-none" type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                </div>
                                <button type="submit" onClick={loginHandler} className="block w-full bg-[#a73233] mt-4 py-2 rounded-2xl text-white font-semibold mb-2">Login</button>
                                {/* <span className="text-sm ml-2 hover:text-blue-500 cursor-pointer">Forgot Password ?</span> */}
                            </form>
                        </div>
                    </div >

                </div>
            </div>
        </div>


























    );
}

export default Login;
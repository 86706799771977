import { FaBars, FaUserCircle } from 'react-icons/fa';
import menu from '../../json/menu_panel.json'
import { useState } from 'react'

import unes_s from "./../../assets/img/unes-small.png";
import { NavLink, useNavigate } from 'react-router-dom';
import { isAuth } from '../../helper/auth';
import { removeLocalStorage } from '../../helper/auth';

function Header() {
    const [flyer, setFlyer] = useState(false);
    const nav = useNavigate();

    let [open, setOpen] = useState(false);
    const user = isAuth()

    // const scrollToSection = (event) => {
    //     event.preventDefault();
    //     const targetElement = document.querySelector(event.target.getAttribute('href'));
    //     if (targetElement) {
    //         targetElement.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }
    async function logOut() {
        removeLocalStorage()
        nav('/login')
    }
    return (

        <div className='shadow-lg  w-full fixed z-10 top-0 left-0'>
            <div className='md:flex bg-[#60282a] items-center justify-between py-3 md:px-10 px-4'>
                <div className='font-bold text-xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
                    <NavLink to={'/dashboard'}>
                        <div className=' flex'>
                            <div className=' flex items-center'>
                                <img src={unes_s} className=' md:w-16 w-12' alt='unes' />
                            </div>
                            <div className=' m-auto  font-sans '>
                                <div className=' m-0 flex items-center text-md md:text-3xl text-white mr-1 px-2 font-sans '>
                                    UNIVERSITAS EKASAKTI
                                </div>
                                <div className=' leading-3 m-0 flex justify-start w-full items-center text-[11px] md:text-sm  text-white  px-2 font-extralight '>
                                    Jl. Veteran No.26B, Purus, Kec. Padang Barat
                                </div>
                            </div>
                        </div>
                    </NavLink>

                </div>

                <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 text-white cursor-pointer md:hidden'>
                    <FaBars />
                </div>

                <ul className={`md:flex  md:items-center md:pb-0  bg-[#60282a] md:bg-transparent md:shadow-none shadow-xl absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto  transition-all duration-500 ease-in ${open ? 'top-[70px] ' : 'top-[-490px]'}`}>
                    {
                        menu.map((link) => (
                            <li key={link.name} className=' hover:bg-[#523434] bg-[#60282a] py-2 px-4 md:rounded-full rounded-none text-md md:my-0 '>
                                <NavLink to={link.link} >
                                    <div className='text-white  font-semibold hover:text-white duration-500'>{link.name}</div>
                                </NavLink>
                            </li>
                        ))

                    }
                    <li className=' md:mt-0 mt-4  md:text-white flex  justify-center items-center '>
                        <div className="relative p-4 md:0 w-full h-full flex rounded-lg bg-[#693537] justify-center">
                            <button type="button" className="grou rounded-md text-gray-500 inline-flex items-center text-base font-sm hover:text-gray-200 focus:outline-none  active:outline-none focus:ring-offset-2 focus:ring-indigo-500 pb-8'" onClick={() => (setFlyer(!flyer))}>

                                <div className=' md:0 w-full h-full flex rounded-lg bg-[#693537] justify-center'>
                                    <span className=' text-white px-2 text-3xl '><FaUserCircle /></span>
                                    <span className=' text-white font-sans text-lg '> {user?.nama} - {user?.username}</span>
                                </div>
                                <svg className={flyer === true
                                    ? "transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200"
                                    : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </button>
                            {/* onMouseLeave={() => setFlyer(false)} */}
                            <div onMouseLeave={() => setFlyer(false)} className={flyer ? "  opacity-100 translate-y-[20%] translate-x-[0%] transition ease-out duration-200 absolute     transform px-2 w-screen max-w-md sm:px-0 lg:ml-0  /2 md:-translate-x-[17%]" : " opacity-0 translate-y-[20%] absolute z-0  -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 translate-x-[-22%] md:translate-x-[90%]"}  >
                                <div className="rounded-lg shadow-lg overflow-hidden">
                                    <div className="px-5 py-3 shadow-md bg-gray-50 space-y-6 justify-start sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">

                                        <div className="flow-root">
                                            <div className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                                {/* <span className="mr-2">Profile</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative h-[200px] w-full flex flex-col items-center  bg-white px-5 py-6">
                                        <div className=' p-2 text-black px-2 text-8xl '><FaUserCircle /></div>
                                        <div className=' text-black font-sans text-lg '> {user?.nama}</div>
                                    </div>
                                    <div className="px-5 py-5 bg-gray-50 space-y-6 justify-start sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">

                                        <div className="flow-root">
                                            <div onClick={logOut} className=" cursor-pointer -m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                                <span className="mr-2">LogOut</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M4.85 22.05q-1.425 0-2.412-.988-.988-.987-.988-2.412V5.35q0-1.425.988-2.413.987-.987 2.412-.987h7.3v3.4h-7.3v13.3h7.3v3.4ZM16 18.575l-2.4-2.4 2.475-2.475h-7.6v-3.4h7.6L13.6 7.825l2.4-2.4L22.55 12Z" /></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
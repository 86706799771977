import { useState, useEffect } from 'react';
import { ValidationDataAlamat } from '../../../helper/validation';
import axios from 'axios';
import { isAuth } from "../../../helper/auth";
import provincesData from '../../../json/provinsi.json'; // Import data provinsi

const AddressDataForm = ({ onSubmitSuccess, status }) => {
    const user = isAuth();
    const token = JSON.parse(localStorage.getItem("token"));
    const header = { Authorization: `Bearer ${token}` };
    const [formDataAlamat, setFormDataAlamat] = useState({
        alamatLengkap: '',
        rt: '',
        rw: '',
        kodePos: '',
        noTelp: '',
        dusun: '',
        provinsi: '',
        kabupatenKota: '',
        kelurahan: '',
        kecamatan: '',
    });
    const [errors, setErrors] = useState({});
    const [selectedProvince, setSelectedProvince] = useState('');
    const [cities, setCities] = useState([]);
    const readOnly = status === "N";
    const disable = status === "N";
    useEffect(() => {
        getDataAlamat();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedProvince) {
            const province = provincesData.find(p => p.PropinsiID === selectedProvince);

            if (province) {
                setCities(province.Daerah);
            }
        }
    }, [selectedProvince]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newData = { ...formDataAlamat, [name]: value };
        setFormDataAlamat(newData);
        setErrors(ValidationDataAlamat(newData));
        if (name === 'provinsi') {
            setSelectedProvince(value);
            setFormDataAlamat(prevState => ({ ...prevState, kabupatenKota: '' })); // Reset kabupatenKota when provinsi changes
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = ValidationDataAlamat(formDataAlamat);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            await axios.patch(`api/formulir/dataAlamat/${user?.username}`, formDataAlamat, { headers: header })
                .then((res) => {
                    onSubmitSuccess();
                    console.log("Form data is valid. Submitting form...");
                }).catch((e) => {
                    console.log(e);
                });
        } else {
            console.log("Form data has errors. Not submitting form.");
        }
    };

    const getDataAlamat = () => {
        axios.get(`api/formulir/dataAlamat/${user?.username}`, { headers: header })
            .then((v) => {
                setFormDataAlamat({
                    alamatLengkap: v.data[0].Alamat || '',
                    rt: v.data[0].RT || '',
                    rw: v.data[0].RW || '',
                    kodePos: v.data[0].KodePos || '',
                    noTelp: v.data[0].Handphone || '',
                    dusun: v.data[0].Dusun || '',
                    provinsi: v.data[0].Propinsi || '',
                    kabupatenKota: v.data[0].Kabupaten || '',
                    kelurahan: v.data[0].Kelurahan || '',
                    kecamatan: v.data[0].Kecamatan || '',
                });
                setSelectedProvince(v.data[0].Propinsi || '');
            });
    };


    // const transformProvinsiDataToArray = () => {
    //     const nestedProvinsi = {};

    //     // Grouping data by province
    //     Provinsi.forEach(item => {
    //         if (!nestedProvinsi[item.NamaPropinsi]) {
    //             nestedProvinsi[item.NamaPropinsi] = {
    //                 PropinsiID: item.PropinsiID,
    //                 Daerah: []
    //             };
    //         }
    //         nestedProvinsi[item.NamaPropinsi].Daerah.push({
    //             DaerahID: item.DaerahID,
    //             NamaDaerah: item.NamaDaerah,
    //             Luas: item.Luas,
    //             JmlPenduduk: item.JmlPenduduk,
    //             JMDPTTBPRO: item.JMDPTTBPRO,
    //             NA: item.NA
    //         });
    //     });

    //     // Converting the object to an array of objects
    //     return Object.keys(nestedProvinsi).map(key => ({
    //         NamaPropinsi: key,
    //         PropinsiID: nestedProvinsi[key].PropinsiID,
    //         Daerah: nestedProvinsi[key].Daerah
    //     }));
    // };

    // const nestedProvinsiArray = transformProvinsiDataToArray();
    // console.log(nestedProvinsiArray);

    return (
        <form onSubmit={handleSubmit} className="md:mt-4 mb-2 w-full p-0 md:p-4">
            <div className=' md:grid md:grid-cols-2'>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Alamat Lengkap</label>
                        <textarea value={formDataAlamat.alamatLengkap} onChange={(e) => handleChange(e)} name="alamatLengkap" type="text" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full disabled:bg-gray-100 disabled:text-gray-400 p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} rows="5"></textarea>
                        <small className=' text-left flex text-red-500'>{errors.alamatLengkap && errors.alamatLengkap}</small>
                    </div>
                    <div className=' grid grid-cols-2'>
                        <div className="w-full md:pr-2 ">
                            <label className="block mb-1 text-left text-sm font-light text-gray-700">RT</label>
                            <input value={formDataAlamat.rt} onChange={(e) => handleChange(e)} name="rt" type="number" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        </div>
                        <div className="w-full md:pl-2 ">
                            <label className="block mb-1 text-left text-sm font-light text-gray-700">RW</label>
                            <input value={formDataAlamat.rw} onChange={(e) => handleChange(e)} name="rw" type="number" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        </div>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Kode Pos</label>
                        <input value={formDataAlamat.kodePos} onChange={(e) => handleChange(e)} name="kodePos" type="number" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        <small className=' text-left flex text-red-500'>{errors.kodePos && errors.kodePos}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">No Telp. </label>
                        <input value={formDataAlamat.noTelp} onChange={(e) => handleChange(e)} name="noTelp" type="number" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        <small className=' text-left flex text-red-500'>{errors.noTelp && errors.noTelp}</small>
                    </div>
                </div>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Provinsi</label>
                        <select value={formDataAlamat.provinsi} onChange={(e) => handleChange(e)} name="provinsi" className={`bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }}>
                            <option value="">Pilih Provinsi</option>
                            {provincesData.map((province, i) => {
                                return (
                                    <option key={i} value={province.PropinsiID}>{province.NamaPropinsi}</option>
                                )
                            })}
                        </select>
                        <small className='text-left flex text-red-500'>{errors.provinsi && errors.provinsi}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Kabupaten/Kota</label>
                        <select value={formDataAlamat.kabupatenKota} onChange={(e) => handleChange(e)} name="kabupatenKota" className={`bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }}>
                            <option value="">Pilih Kabupaten/Kota</option>
                            {cities.map((city, i) => {
                                return (
                                    <option key={i} value={city?.DaerahID}>{city?.NamaDaerah}</option>
                                )
                            })}
                        </select>
                        <small className='text-left flex text-red-500'>{errors.kabupatenKota && errors.kabupatenKota}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Kelurahan</label>
                        <input value={formDataAlamat.kelurahan} onChange={(e) => handleChange(e)} name="kelurahan" type="text" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        <small className=' text-left flex text-red-500'>{errors.kelurahan && errors.kelurahan}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Kecamatan</label>
                        <input value={formDataAlamat.kecamatan} onChange={(e) => handleChange(e)} name="kecamatan" type="text" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        <small className=' text-left flex text-red-500'>{errors.kecamatan && errors.kecamatan}</small>
                    </div>
                    <div className="w-full ">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Dusun </label>
                        <input value={formDataAlamat.dusun} onChange={(e) => handleChange(e)} name="dusun" type="text" className={`bg-gray-50 border border-[#bca0a1]  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${readOnly}`} readOnly={readOnly} disabled={disable} style={{ backgroundColor: disable ? '#f5f5f5' : '', color: disable ? '#646464' : '' }} />
                        <small className=' text-left flex text-red-500'>{errors.dusun && errors.dusun}</small>
                    </div>

                </div>
            </div>
            {
                status === 'Y' ? (<button className='bg-[#60282a] hover:bg-red-900 text-white font-bold py-2 px-4 rounded ' type='submit'>Selanjutnya</button>) : ""
            }
        </form>
    );
};

export default AddressDataForm;
import { useState, useEffect } from 'react';
import { ValidationDataSekolah } from '../../../helper/validation';
import axios from 'axios';
const SekolahDataForm = ({ AplikanID, onSubmitSuccess, status }) => {
    const token = JSON.parse(localStorage.getItem("token"))
    const header = { Authorization: `Bearer ${token}` }
    const [formDataSekolah, setFormDataSekolah] = useState(
        {
            AsalSekolah: '',
            Jurusan: '',
            NilaiUjian: '',
            AlamatSekolah: '',
            TahunLulus: '',
            PendidikanTerakhir: '',
            NilaiRapor: '', // New field added
        });
    const readOnly = status === "N";
    const disable = status === "N";
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getDataSekolah()
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newData = { ...formDataSekolah, [name]: value };
        setFormDataSekolah(newData);
        setErrors(ValidationDataSekolah(newData));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = ValidationDataSekolah(formDataSekolah);
        setErrors(validationErrors);
        console.log(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            await axios.patch(`api/formulir/dataSekolah/${AplikanID}`, formDataSekolah, { headers: header })
                .then((res) => {
                    onSubmitSuccess();
                    console.log("Form data is valid. Submitting form...");
                }).catch((e) => {
                    console.log(e);
                });
        } else {
            console.log("Form data has errors. Not submitting form.");
        }
    }

    const getDataSekolah = () => {
        axios.get(`api/formulir/dataSekolah/${AplikanID}`, { headers: header })
            .then((v) => {
                setFormDataSekolah(
                    {
                        AsalSekolah: v.data[0].AsalSekolah || '',
                        Jurusan: v.data[0].Jurusan || '',
                        NilaiUjian: v.data[0].NilaiUjian || '',
                        AlamatSekolah: v.data[0].AlamatSekolah || '',
                        TahunLulus: v.data[0].TahunLulus || '',
                        PendidikanTerakhir: v.data[0].PendidikanTerakhir || '',
                        NilaiRapor: v.data[0].NilaiRapor || '', // New field added
                    }
                )
            })
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="md:mt-4 mb-2 w-full p-0 md:p-4">
            <div className='md:grid md:grid-cols-2'>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">

                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Pendidikan Terakhir</label>
                        <select value={formDataSekolah.PendidikanTerakhir} onChange={(e) => handleChange(e)} name="PendidikanTerakhir" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable}>
                            <option value="">Pilih Pendidikan Terakhir</option>
                            <option value="SD">SD</option>
                            <option value="SMP">SMP</option>
                            <option value="SMA">SMA</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Sarjana">Sarjana</option>
                            <option value="Magister">Magister</option>
                            <option value="Doktor">Doktor</option>
                        </select>
                        <small className='text-left flex text-red-500'>{errors.PendidikanTerakhir && errors.PendidikanTerakhir}</small>
                    </div>
                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Asal Sekolah</label>
                        <input value={formDataSekolah.AsalSekolah} onChange={(e) => handleChange(e)} name="AsalSekolah" type="text" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className='text-left flex text-red-500'>{errors.AsalSekolah && errors.AsalSekolah}</small>
                    </div>
                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Asal Sekolah</label>
                        <input value={formDataSekolah.AlamatSekolah} onChange={(e) => handleChange(e)} name="AlamatSekolah" type="text" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className='text-left flex text-red-500'>{errors.AlamatSekolah && errors.AlamatSekolah}</small>
                    </div>
                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Tahun Lulus</label>
                        <input value={formDataSekolah.TahunLulus} onChange={(e) => handleChange(e)} name="TahunLulus" type="text" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className='text-left flex text-red-500'>{errors.TahunLulus && errors.TahunLulus}</small>
                    </div>
                </div>
                <div className="mb-4 w-full flex flex-col gap-3 px-2">
                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Jurusan</label>
                        <input value={formDataSekolah.Jurusan} onChange={(e) => handleChange(e)} name="Jurusan" type="text" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className='text-left flex text-red-500'>{errors.Jurusan && errors.Jurusan}</small>
                    </div>
                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Nilai Ujian</label>
                        <input value={formDataSekolah.NilaiUjian} onChange={(e) => handleChange(e)} name="NilaiUjian" type="text" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className='text-left flex text-red-500'>{errors.NilaiUjian && errors.NilaiUjian}</small>
                    </div>
                    <div className="w-full">
                        <label className="block mb-1 text-left text-sm font-light text-gray-700">Nilai Rapor</label>
                        <input value={formDataSekolah.NilaiRapor} onChange={(e) => handleChange(e)} name="NilaiRapor" type="text" className="bg-gray-50 border border-[#bca0a1] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:bg-gray-100 disabled:text-gray-400" readOnly={readOnly} disabled={disable} />
                        <small className='text-left flex text-red-500'>{errors.NilaiRapor && errors.NilaiRapor}</small>
                    </div>
                </div>
            </div>
            {
                status === 'Y' ? (<button className='bg-[#60282a] hover:bg-red-900 text-white font-bold py-2 px-4 rounded ' type='submit'>Selanjutnya</button>) : ""
            }
        </form>
    );
};

export default SekolahDataForm;

import { FaBars } from 'react-icons/fa';
import menu from '../../json/menu_home.json'
import React, { useState } from 'react'

import unes_s from "./../../assets/img/unes-small.png";
import { NavLink } from 'react-router-dom';
function Header() {

    let [open, setOpen] = useState(false);

    const scrollToSection = (event) => {
        event.preventDefault();
        const targetElement = document.querySelector(event.target.getAttribute('href'));
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (

        <div className='shadow-lg  w-full fixed z-10 top-0 left-0'>
            <div className='md:flex bg-[#60282a] items-center justify-between py-3 md:px-10 px-4'>
                <div className='font-bold text-xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
                    <NavLink to={'/'}>
                        <div className=' flex'>
                            <div className=' flex items-center'>
                                <img src={unes_s} className=' md:w-16 w-12' alt='unes' />
                            </div>
                            <div className=' m-auto  font-sans '>
                                <div className=' m-0 flex items-center text-md md:text-3xl text-white mr-1 px-2 font-sans '>
                                    UNIVERSITAS EKASAKTI
                                </div>
                                <div className=' md:tracking-[3px] md:text-[1.05rem] text-[0.9rem] leading-3 m-0 flex justify-start w-full items-center    text-white  px-2 '>
                                    AKADEMI AKUNTANSI INDONESIA
                                </div>
                            </div>
                        </div>
                    </NavLink>

                </div>

                <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 text-white cursor-pointer md:hidden'>
                    <FaBars />
                </div>

                <ul className={`md:flex md:items-center md:px-2 md:py-1 md:bg-white md:rounded-full bg-[#60282a] md:bg-transparent md:shadow-none shadow-lg absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto  transition-all duration-500 ease-in ${open ? 'top-[70px] ' : 'top-[-490px]'}`}>
                    {
                        menu.map((link) => (
                            <li key={link.name} className=' hover:text-black md:bg-transparent bg-[#60282a] p-2  rounded-none text-md  md:my-0 my-0'>
                                {link.link === '#biaya' ? (
                                    <a href={link.link} onClick={scrollToSection} className='md:text-gray-800 hover:text-black text-white font-semibold  duration-500'>{link.name}</a>
                                ) : (
                                    <NavLink to={link.link} >
                                        <div className=' md:text-gray-800 text-white font-semibold hover:text-gray-500 duration-500'>{link.name}</div>
                                    </NavLink>
                                )}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default Header;
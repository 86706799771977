// import { useNavigate } from "react-router-dom";
// import { isAuth } from "../helper/auth";

// import { useEffect, useRef } from "react";

import Header from "../component/Home/Header";
import Footer from "../component/Home/Footer";

const HomeLayout = ({ children }) => {
    // let baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3002" : "https://disposisi.unespadang.ac.id"
    // const user = isAuth()
    // const audioRef = useRef < HTMLAudioElement | null > (null);
    // const nav = useNavigate();
    // useEffect(() => {
    //     const username = user?.nama;
    // }, [nav])


    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
}

export default HomeLayout;


import { useState, useEffect } from "react";

function MidtransSnap() {
    const [snap, setSnap] = useState(null)

    useEffect(() => {
        // You can also change below url value to any script url you wish to load, 
        // for example this is snap.js for Sandbox Env (Note: remove `.sandbox` from url if you want to use production version)
        const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js';
        const myMidtransClientKey = 'SB-Mid-client-EvHJBEW9wYPXVHO_';

        let scriptTag = document.createElement('script');
        scriptTag.src = midtransScriptUrl;
        scriptTag.setAttribute('data-client-key', myMidtransClientKey);
        scriptTag.onload = () => {
            setSnap(window.snap)
        }
        document.body.appendChild(scriptTag);

        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    // d654dc50 - 10ca - 48f9 - a4ff - 8c72b1a26e5e snap_token

    const snapEmbeded = (snap_token, embedId) => {
        if (snap) {
            snap.embed(snap_token, {
                embedId
                // onSuccess: (result) => {
                //     action.onSuccess(result)
                // },
                // onPendding: (result) => {
                //     action.onPendding(result)
                // },
                // onClose: () => {
                //     action.onClose()
                // },
            })

        }

    }


    return { snapEmbeded };
}

export default MidtransSnap;
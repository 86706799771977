import { FaCloudDownloadAlt, FaInfoCircle } from 'react-icons/fa';
import 'react-image-lightbox/style.css'; // Import CSS for React Lightbox
import axios from 'axios';
import { isAuth } from "../../../helper/auth";
const SelesaiPage = ({ onSubmitSuccess, status }) => {
    const user = isAuth()
    const token = JSON.parse(localStorage.getItem("token"))
    const header = { Authorization: `Bearer ${token}` }
    const downloadPDF = async () => {
        await axios({
            url: `api/formulir/pdf/${user.username}`, //your url
            method: 'GET',
            headers: header,
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `PMB-098.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
        })
    }
    return (
        <>
            <div className="md:mt-4 mb-2 w-full p-0 md:p-4 ">
                <div className="mb-4 w-full justify-center flex items-center  px-2">
                    <div className="flex h-full items-center justify-center bg-white px-6 md:px-60">
                        <div className="space-y-6 border-l-2 border-dashed">
                            <div className="relative w-full">
                                <div className=" flex justify-center items-center bg-[#693537] absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 font-bold rounded-full text-white">1</div>
                                <div className="">
                                    <h4 className=" bg-gray-100 font-bold text-gray-800"> Isi Formulir.</h4>
                                    <p className="mt-2 max-w-screen-sm text-sm text-gray-500">Mengisi Formulir pendaftaran</p>
                                </div>
                            </div>
                            <button onClick={downloadPDF} className="m-6 shadow-lg bg-white hover:bg-gray-100 border-2 border-[#693537] hover:bg-grey text-[#693537] font-bold py-2 px-4 rounded inline-flex items-center">
                                <span className=' text-xl p-2'><FaCloudDownloadAlt /></span>
                                <span>Unduh Bukti Pendaftaran</span>
                            </button>
                            <div className=' flex items-center p-4 text-left'>
                                <div className=' text-yellow-400 text-3xl p-3'><FaInfoCircle /></div>
                                <div> Bagi mahasiswa baru telah mengisi biodata silahkan datang untuk pendaftaran ulang di unes gedung rektorat lantai 1</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelesaiPage;